<template>
  <div>
    <div style="text-align: left; margin: 20px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
        >
        </el-pagination>
      </div>
  </div>
</template>

<script>
export default {
  name:'Pagination',
  props:{
    queryInfo:{
      type:Object,
      default(){
        return {}
      }
    },
    total:{
      type:Number,
      default(){
        return 0
      }
    }
  },
  methods: {
    handleSizeChange(newSize){
      this.$emit('handleSizeChange',newSize)
    },
    handleCurrentChange(newPage){
      this.$emit('handleCurrentChange',newPage)
    }
  },
}
</script>

<style>

</style>